<template>
  <div class="unOuter mainOuter">
    <b-overlay
        :show=show
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
        style="max-width: 100%"
    >
      <div class="container_un">
        <div class="subWhite animated fadeInLeft ani_delay05">
          <div class="sub_bg_bot"></div>
          <div class="subTitBox">
            <p class="tit tit_sub">
              <span id="t_page_title" class="subTitle">{{$t('subscribe.title')}}</span>
            </p>
          </div>
          <div class="RightBox">
            <div class="gpcWrap">
              <div id="prodList">
                <div class="gBoxN_01 card_bg0 motion"
                     v-for="(item,index) in plan_list"
                     :key="index">
                  <div class="ttitle_box">
                    <p class="tTitle_logo">
                      <span class="tit_lg text-center my-auto"><i
                          class="fal fa-chart-bar text-white fs-4"></i></span>
                    </p>
                    <p class="tTitle2">
                      <span class="midNum"></span>
                      <br>
                      <span class="smNum"></span>
                    </p>
                  </div>
                  <p class="arabic1 mb-1">{{ item.title }}</p>
                  <p class="arabic">
                    <span class="dol">$</span>
                    <span id="t_top_usd00009" class="bigNum">{{ item.balance }}</span>
                  </p>
                  <p class="tTitle mt-3">{{$t('subscribe.days')}}<br/><span v-html="item.describe"></span></p>
                  <p class="tTitle"><span
                      v-if="item.promotion === 'Y'">{{$t('subscribe.promotion')}} : {{ item.sale_day }} Days</span></p>
                  <div class="clearfix"></div>
                  <p class="btnBuy motion t_RVN_order ng-star-inserted" v-if="item.no != 8 && my_plan > item.plan"
                     v-b-modal.modal-1 @click="checkSales(item)">{{$t('subscribe.downGrade')}}</p>

                  <p class="btnBuy motion t_RVN_order ng-star-inserted"
                     style="color: goldenrod; font-weight: 600;" v-if="item.no != 8 && my_plan == item.plan">{{$t('subscribe.subscribing')}}</p>

                  <p class="btnBuy motion t_RVN_order ng-star-inserted"
                     style="color: #ff5886; font-weight: 600;" v-if="item.no != 8 && my_plan < item.plan"
                     v-b-modal.modal-1 @click="checkSales(item)">{{my_plan == 0 ? $t('subscribe.subscribe') : $t('subscribe.upGrade')}}</p>

                  <p class="btnBuy motion t_RVN_order ng-star-inserted"
                     style="color: #ff5886; font-weight: 600;" v-if="item.no == 8 && !autoBot"
                     v-b-modal.modal-1 @click="checkSales(item)">{{my_plan < 4 ? $t('subscribe.subscribe') : $t('subscribe.subscribing')}}</p>
                  <p class="btnBuy motion t_RVN_order ng-star-inserted"
                     style="color: #ff5886; font-weight: 600;" v-if="item.no == 8 && autoBot">{{$t('subscribe.subscribing')}}</p>
                </div>
              </div>
            </div>
            <p name="t_warning" style="color: #fff; margin-top: 1em;">
              {{$t('subscribe.desc1')}}
              <br>
              {{$t('subscribe.desc2')}}
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal id="modal-1" :title="this.$t('subscribe.titleBuy')" hide-footer>
      <div class="ng-star-inserted">
        <h3 style="text-align: center; font-size: 18px; color: #666;">{{ title }}</h3>
        <div
            class="ng-untouched ng-pristine ng-invalid">
          <div class="form-group mt-2 mb-2">
            <label class='mb-2'>{{$t('subscribe.price')}}</label>
            <input type="text" class="form-control ng-untouched ng-pristine ng-invalid text-black"
                   style="height: 50px; margin: auto; display: block;" v-model="balance" disabled>
          </div>
          <div class="form-group mt-2 mb-2">
            <label class='mb-2'>{{$t('subscribe.tronAmount')}}</label>
            <input type="text" class="form-control ng-untouched ng-pristine ng-invalid text-black"
                   style="height: 50px; margin: auto; display: block;" v-model="trx_balance"
                   disabled>
          </div>
          <div class="form-group mt-2 mb-2">
            <label class='mb-2'>{{$t('subscribe.usdtAmount')}}</label>
            <input type="text" class="form-control ng-untouched ng-pristine ng-invalid text-black"
                   style="height: 50px; margin: auto; display: block;" v-model="usdt_balance"
                   disabled>
          </div>
          <div class="form-group text-center" v-if="show == false">
            <button class="btn btn-primary btnCopy1 border-0" style="width: 250px; height: 55px;"
                    @click="sales()">{{$t('subscribe.buy')}}
            </button>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>


const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
export default {
  data() {
    return {
      show: false,
      my_plan: '',
      plan_list: [],
      trx_balance: '',
      usdt_balance: '',
      title: '',
      balance: '',
      t_no: '',
      t_plan: ''
    }
  },
  components: {},
  mounted() {
    this.getPlanList();
    this.getWalletInfo();
  },
  methods: {
    checkSales(item) {
      this.t_no = item.no;
      this.t_plan = item.plan;
      this.title = item.title;
      this.balance = item.balance;
    },
    getWalletInfo() {
      this.$axios.post('/member/coin/GetWalletInfo', {}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.address = body.wallet.address;
                this.trx_balance = body.wallet.trx_balance;
                this.usdt_balance = body.wallet.usdt_balance;
              } else if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    sales() {
      const balance = this.balance;

      const usdt_balance = this.usdt_balance;

      const c_balance = exactMath.sub(usdt_balance, balance);

      if (c_balance < 0) {
        this.$alert(this.$i18n.t('subscribe.alertLackUsdt'))
        return false;
      }

      const p_no = this.t_no
      const body = {p_no};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();
      this.show = true;
      this.$axios.post('/member/plan/Sales', {req}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.show = false;
                window.localStorage.setItem("plan", res.data.plan);
                this.$router.go();
              } else if (res.data.code === 300) {
                this.$alert(this.$i18n.t('subscribe.alertUsingWallet')).then(
                    () => {
                      this.$router.go();
                    }
                )
              } else if (res.data.code === 400) {
                this.$alert(this.$i18n.t('subscribe.alertLackTron')).then(
                    () => {
                      this.$router.go();
                    }
                )
              } else if (res.data.code === 401) {
                this.$alert(this.$i18n.t('subscribe.alertLackUsdtRet')).then(
                    () => {
                      this.$router.go();
                    }
                )
              } else if (res.data.code === 500) {
                this.$alert(this.$i18n.t('subscribe.alertFailTransfer')).then(
                    () => {
                      this.$router.go();
                    }
                )
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    getPlanList() {
      this.$axios.post('/member/plan/GetPlanList', {}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res);
                this.plan_list = body.list;

                switch (body.plan) {
                  case 4:
                    this.my_plan = 4;
                    this.autoBot = true;
                    break;
                  case 5:
                    this.my_plan = 1;
                    this.autoBot = true;
                    break;
                  case 6:
                    this.my_plan = 2;
                    this.autoBot = true;
                    break;
                  default:
                    this.my_plan = body.plan;
                    this.autoBot = false;
                }
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },

  }

}
</script>
